define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-nav-item", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _getUrl, _decorators, _I18n, _activityPubActor, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a
    class={{this.classes}}
    href={{this.href}}
    title={{this.title}}
    {{did-insert this.setup}}
    {{did-update this.changeCategory @category}}
    {{did-update this.changeTag @tag}}
    {{will-destroy this.teardown}}
  >
    {{d-icon "discourse-activity-pub"}}
    {{i18n "discourse_activity_pub.discovery.label"}}
  </a>
  */
  {
    "id": "Q28dnc3e",
    "block": "[[[11,3],[16,0,[30,0,[\"classes\"]]],[16,6,[30,0,[\"href\"]]],[16,\"title\",[30,0,[\"title\"]]],[4,[38,0],[[30,0,[\"setup\"]]],null],[4,[38,1],[[30,0,[\"changeCategory\"]],[30,1]],null],[4,[38,1],[[30,0,[\"changeTag\"]],[30,2]],null],[4,[38,2],[[30,0,[\"teardown\"]]],null],[12],[1,\"\\n  \"],[1,[28,[35,3],[\"discourse-activity-pub\"],null]],[1,\"\\n  \"],[1,[28,[35,4],[\"discourse_activity_pub.discovery.label\"],null]],[1,\"\\n\"],[13]],[\"@category\",\"@tag\"],false,[\"did-insert\",\"did-update\",\"will-destroy\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-nav-item.hbs",
    "isStrictMode": false
  });
  class ActivityPubNavItem extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "visible", [_tracking.tracked], function () {
      return false;
    }))();
    #visible = (() => (dt7948.i(this, "visible"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "actor", [_tracking.tracked]))();
    #actor = (() => (dt7948.i(this, "actor"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "model", [_tracking.tracked]))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    setup() {
      this.messageBus.subscribe("/activity-pub", this.handleActivityPubMessage);
      this.changeCategory();
      this.changeTag();
    }
    static #_8 = (() => dt7948.n(this.prototype, "setup", [_decorators.bind]))();
    teardown() {
      this.messageBus.unsubscribe("/activity-pub", this.handleActivityPubMessage);
    }
    static #_9 = (() => dt7948.n(this.prototype, "teardown", [_decorators.bind]))();
    changeCategory() {
      if (this.args.category) {
        this.model = this.args.category;
        this.modelType = "category";
        this.modelName = this.model.name;
        this.changeModel();
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "changeCategory", [_decorators.bind]))();
    changeTag() {
      if (this.args.tag) {
        this.model = this.args.tag;
        this.modelType = "tag";
        this.modelName = this.model.id;
        this.changeModel();
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "changeTag", [_decorators.bind]))();
    changeModel() {
      const actor = _activityPubActor.default.findByModel(this.model, this.modelType);
      if (actor && this.canAccess(actor)) {
        this.actor = actor;
        this.visible = true;
      } else {
        this.actor = null;
        this.visible = false;
      }
    }
    canAccess(actor) {
      return this.site.activity_pub_publishing_enabled || actor.can_admin;
    }
    handleActivityPubMessage(data) {
      if (_activityPubActor.actorModels.includes(data.model.type) && this.model && data.model.id.toString() === this.model.id.toString()) {
        this.visible = data.model.ready;
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "handleActivityPubMessage", [_decorators.bind]))();
    get classes() {
      let result = "activity-pub-route-nav";
      if (this.visible) {
        result += " visible";
      }
      if (this.active) {
        result += " active";
      }
      return result;
    }
    get href() {
      if (!this.actor) {
        return;
      }
      const path = this.site.activity_pub_publishing_enabled ? "followers" : "follows";
      return (0, _getUrl.default)(`${_activityPubActor.actorClientPath}/${this.actor.id}/${path}`);
    }
    get title() {
      if (!this.model) {
        return "";
      }
      return _I18n.default.t("discourse_activity_pub.discovery.description", {
        model_name: this.modelName
      });
    }
    get active() {
      return this.router.currentRouteName.includes(`activityPub.actor`);
    }
  }
  _exports.default = ActivityPubNavItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubNavItem);
});